/* .notification-item {
    height: 100px;
    width: 100%;
    border: 1px solid black;
    border-radius: 5px;
}

.body-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
} */

.drawer-open {
    position: fixed;
    height: 100%;
    width: 430px;
    top: 0;
    right: 0;
    background: rgb(214, 214, 230);
    transition: width 0.5s ease;
    border-left: 1px solid rgb(213, 214, 215);
    overflow: scroll;
}

@media only screen and (max-width: 430px) {
    .drawer-open {
        width: 100%;
    }
    .min-max-date-portion {
        font-size: 10px !important;
    }
}

.drawer-close {
    width: 0px;
    display: none;
}

.header-section {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 5rem;
    border-bottom: 1px solid gray;
    position: sticky;
    top: 0;
}

.drawer-title {
    font-size: 20px;
}

.drawer-body {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: -1;
    position: static;
}

.drawer-body-element {
    height: 100px;
    width: 100%;
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px;
    z-index: -1;
    display: flex;
}

.drawer-element-left-portion {
    width: 20%;
    display: flex;
    align-items: center;
    border-right: 2px solid rgb(221, 220, 220);
}

.drawer-element-right-portion {
    width: 80%;
    padding-left: 5px;
}

.min-max {
    display: flex;
    gap: 1rem;
    font-weight: bold;
}

.min-max-date-portion {
    display: flex;
    font-size: 13px;
    gap: 1rem
}

.value,
.message {
    font-size: 13px;
}

.value {
    font-weight: bold;
}

.icon-tooltip {
    position: absolute;
    background: red;
    color: wheat;
    border-radius: 90%;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-weight: bold; */
    top: 0;
    margin-top: 18px;
    margin-left: 10px;
}